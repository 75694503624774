import React from "react";
import "./Search.sass";
function Search({ value, placeholder, onChange, events }) {
  return (
    <div style={{ position: "sticky", top: "110px" }} className="search">
      <input
        style={{
          color: events.search_input_color,
          backgroundColor: events.search_input_background,
        }}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <button onClick={() => onChange("")}>✕</button>
    </div>
  );
}

export default Search;
